import { configureStore } from "@reduxjs/toolkit";
import userLangReducer from "./slices/userLang";
import userAuthReducer from "./slices/userAuth";
import notificationSnackbarReducer from "./slices/notificationSnackbar";
import persistentRegistrationDataReducer from "./slices/persistentRegistrationData";
import userTypeReducer from "./slices/userTypeSlice";
import userProfileReducer from "./slices/userProfile";
import loaderSlice from "./slices/loaderSlice";

export default configureStore({
  reducer: {
    userLang: userLangReducer,
    userAuth: userAuthReducer,
    userType: userTypeReducer,
    notificationSnackbar: notificationSnackbarReducer,
    persistentRegistrationData: persistentRegistrationDataReducer,
    userProfile: userProfileReducer,
    loader: loaderSlice, 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
