import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getNfcCard } from "../../api/nfc.js";
import NotFoundPage from "../../components/NotFoundPage";

// Redux
import { useDispatch } from "react-redux";
import { setNotification } from "../../slices/notificationSnackbar";
import { showLoader } from "../../slices/loaderSlice";

export default function NfcNavigator() {
  const navigate = useNavigate();
  const { code } = useParams();
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  async function fetchNfcData() {
    dispatch(showLoader());
    try {
      const response = await getNfcCard(code);
      const username = response.data.user.user.username;
      const links = response.data.links;
      setIsValid(true);
      if (links.length === 1 && links[0].category === "Review") {
        window.open(`${links[0].url}`, "_blank");
      }
      navigate(`/${username}`);
    } catch (error) {
      setIsValid(false);
      if (error.response.data.redirect_url) {
        window.location.href = error.response.data.redirect_url;
      } else {
        dispatch(
          setNotification({
            open: true,
            message: error.response.data.data
              ? error.response.data.data.error
              : "Something went wrong.",
            severity: "error",
          })
        );
      }
    }
  }

  useEffect(() => {
    clearCacheData();
    fetchNfcData();
  }, []);

  if (!isValid) {
    return <NotFoundPage />;
  }
}
